<template>
    <div class="main-input" :class="{error: !inputModel.isValid, [inputModel.class]: inputModel.class, 'left-icon': inputModel.leftIcon}">
        <textarea 
        v-model="inputModel.value"
        class="multilenes-input input-body" 
        rows="10"
        :readonly="inputModel.readonly" 
        :minlength="inputModel.minlength" 
        :maxlength="inputModel.maxlength"
        ></textarea>
    </div>   
</template>

<style lang="scss">
.multilenes-input {
  width: 100%;
  min-height: 64px;
}
</style>

<script>
import { inputMixin } from '@/utils/input-mixin'
/*
medolValue: {
  placeholder: 'Search target pages, anchor',
  value: null,
  isValid: true,
  clearable: false,
  required: false,
  readonly: false,
  maxlength: 5,
  minlength: 1,
  class: 'lg',
  leftIcon: Search, // import from icons.js
  errorKey: ''
}
*/
export default {
  name: 'InputText',
  mixins: [inputMixin],

  methods: {
      validate() {
          if(this.inputModel.required && !this.inputModel.value) {
            this.inputModel.isValid = false
            this.inputModel.errorKey = 'required'
            return
          }

          if(this.inputModel?.regexp && !this.inputModel?.regexp.test(this.inputModel.value.toLowerCase())) {
            this.inputModel.isValid = false
            this.inputModel.errorKey = 'regexp'
            return
          }
          this.inputModel.errorKey = null
          this.inputModel.isValid = true
      }
  },
}
</script>
