<template>
    <div class="main-input link" :class="{error: !inputModel.isValid, [inputModel.class]: inputModel.class, 'left-icon': inputModel.leftIcon}">
    <div v-if="inputModel.leftIcon" v-html="inputModel.leftIcon" class="l-icon"></div>
      <input type="text" 
        :placeholder="inputModel.placeholder" 
        :readonly="inputModel.readonly" 
        :minlength="inputModel.minlength" 
        :maxlength="inputModel.maxlength" 
        v-model="inputModel.value"/>
        <a v-if="inputModel.value" class="input-link" :href="inputModel.value" target="_blank" v-html="Link"></a>
        <div class="error-icon" v-if="!inputModel.isValid" v-html="InfoCircle"></div>
    </div>   
</template>

<script>
import { inputMixin } from '@/utils/input-mixin'
import { Link } from '@/utils/icons'

export default {
  name: 'InputLink',
  mixins: [inputMixin],

  data() {
    return {
        Link
    }
  },
  
  methods: {
      validate() {
          if(this.inputModel.required && !this.inputModel.value) this.inputModel.isValid = false
          else this.inputModel.isValid = true
      }
  },
}
</script>
<style lang="scss">
.input-link {
    height: 15px;
    width: 15px;
    position: absolute;
    top: 8px;
    right: 9px;
    margin: 5px;
    svg {
        position: relative;
        top:-3px;
        fill: #142D5A;
        height: inherit;
        width: inherit;
    }
}
</style>
