<template>
  <div :class="{ 'is-marked-to-delete': contact.isMarkedToDelete }">
    <div class="title title2">
      <div>
        {{ title2 }}
        <div
          v-if="!this.contact.is_primary.value && !contact.isMarkedToDelete"
          class="icon dotted"
          @click.stop="showContextMenu"
        >
          <span v-html="ThreeTodsVertical"></span>
          <div
            class="context-menu"
            v-if="isShowContextMenu"
            v-click-outside="
              () => {
                showContextMenu()
              }
            "
          >
            <div
              class="menu-item"
              @click="changeToPrimaty"
              v-if="!contact.is_primary.value"
            >
              Change to Primary
            </div>
            <div
              v-if="isCanToDelete && !contact.is_primary.value"
              class="menu-item"
              @click="deleteContact"
            >
              Delete contact
            </div>
          </div>
        </div>
      </div>

      <template v-if="id.value && !isClientContact">
        <div class="links" v-if="invite_status.value === 'Not invited'">
          <div class="main-button orange" @click="sendInvite">Send invite</div>
        </div>

        <div class="links" v-if="invite_status.value === 'Expired'">
          <div class="link">Expired</div>
          <div class="link margined">
            (<span class="underline" @click="sendInvite">Resend</span>)
          </div>
          <div class="link underline" @click="copyLink">Copy link</div>
        </div>

        <div class="links" v-if="invite_status.value === 'Pending'">
          <div class="link">Pending invitation</div>
          <div class="link margined">
            (<span class="underline" @click="preSendInvite">Resend</span>)
          </div>
          <div class="link underline" @click="copyLink">Copy link</div>
        </div>

        <div
          class="links"
          v-if="
            invite_status.value === 'Active' ||
            invite_status.value === 'Accepted'
          "
        >
          <div class="link">
            Invite accepted (<span class="underline" @click="preSendInvite"
              >Resend</span
            >)
          </div>
        </div>
      </template>
    </div>
    <div class="form-row">
      <div class="form-cell" style="width: 40%">
        <div class="label">First name*</div>
        <input-text v-model="first_name" />
      </div>
      <div class="form-cell" style="width: 40%">
        <div class="label">Last name*</div>
        <input-text v-model="last_name" />
      </div>
      <div class="form-cell">
        <div class="label">Email*</div>
        <input-text v-model="email" />
      </div>
    </div>
    <div class="form-row">
      <div class="form-cell">
        <div class="label">Twitter</div>
        <input-link v-model="twitter" />
      </div>
      <div class="form-cell">
        <div class="label">Facebook</div>
        <input-link v-model="facebook" />
      </div>
      <div class="form-cell">
        <div class="label">LinkedIn</div>
        <input-link v-model="linkedin" />
      </div>
    </div>
  </div>
  <alert-yes-no
    v-if="isVisibleConfirmAlert"
    title="Confirm"
    :text="contentDeleteInfo"
    :alertResult="alertConfirmDelete"
  />
  <alert-yes-no
    v-if="isVisibleInviteStatusConfirmAlert"
    title="Confirm"
    :text="contentInviteStatus"
    :alertResult="alertConfirmInviteStatus"
  />
</template>

<script>
import { fetchData, urls } from '@/utils/urls.js'
import vClickOutside from 'click-outside-vue3'
import { ThreeTodsVertical } from '@/utils/icons'
import InputText from '@/components/inputs/InputText'
import InputLink from '@/components/inputs/InputLink'
import AlertYesNo from '@/components/AlertYesNo.vue'

export default {
  name: 'ClientContact',
  components: { InputText, InputLink, AlertYesNo },
  directives: { clickOutside: vClickOutside.directive },
  emits: ['contactValueChanged', 'saveForm'],
  props: {
    contact: { default: {} },
    isActivatedToEdit: { default: true },
    isCanToDelete: { default: true },
    isClientContact: { default: false },
  },

  watch: {
    isActivatedToEdit() {
      this.setIsActivatedToEditInputs(!this.isActivatedToEdit)
    },

    'first_name.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('first_name')
      })
    },

    'last_name.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('last_name')
      })
    },

    'email.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('email')
      })
    },

    'linkedin.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('linkedin')
      })
    },

    'facebook.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('facebook')
      })
    },

    'twitter.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('twitter')
      })
    },

    'id.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('id')
      })
    },

    'invite_status.value'() {
      this.$nextTick(() => {
        this.inputValueChanged('invite_status')
      })
    },

    contact: {
      handler() {
        if (this.id.value !== this.contact.id.value) {
          this.initFormValues(this.contact)
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      isVisibleInviteStatusConfirmAlert: false,
      contentInviteStatus: '',
      contentDeleteInfo: '',
      ThreeTodsVertical,
      isShowContextMenu: false,
      isVisibleConfirmAlert: false,

      is_primary: {
        value: null,
        valueOriginal: null,
        isValid: true,
      },

      id: {
        value: null,
        valueOriginal: null,
      },

      first_name: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: false,
      },

      last_name: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        readonly: false,
      },

      email: {
        valueOriginal: null,
        value: null,
        isValid: false,
        clearable: false,
        required: true,
        regexp:
          /^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        readonly: false,
      },

      linkedin: {
        valueOriginal: null,
        value: null,
        isValid: true,
        clearable: false,
        required: false,
        readonly: false,
      },

      facebook: {
        valueOriginal: null,
        value: null,
        isValid: true,
        clearable: false,
        required: false,
        readonly: false,
      },

      twitter: {
        valueOriginal: null,
        value: null,
        isValid: true,
        clearable: false,
        required: false,
        readonly: false,
      },

      invite_status: {
        valueOriginal: null,
        value: null,
      },

      invite_link: {
        valueOriginal: null,
        value: null,
      },
    }
  },

  created() {
    this.initFormValues(this.contact)
  },

  computed: {
    title2() {
      if (this.contact.id.value <= 0) {
        return `
        ${this.contact.first_name.value || ''} 
        ${this.contact.last_name.value || ''} 
        ${this.contact.is_primary.value ? '(Primary)' : ''}`
      }
      //#${this.contact.id.value}
      return `
      ${this.contact.first_name.value} 
      ${this.contact.last_name.value} 
      ${this.contact.is_primary.value ? '(Primary)' : ''}`
    },
  },

  methods: {
    copyLink() {
      if (!navigator.clipboard) {
        let element = document.createElement('input')
        element.style.position = 'absolute'
        element.style.top = '-999px'
        document.body.appendChild(element)
        element.value = this.invite_link.value
        element.select()
        document.execCommand('copy')
        element.remove()
        this.$notify({
          text: 'Link copied',
          type: 'success',
          duration: 1000,
        })
      } else {
        navigator.clipboard.writeText(this.invite_link.value).then(
          () => {
            this.$notify({
              text: 'Link copied',
              type: 'success',
              duration: 1000,
            })
          },
          (err) => {
            console.error('Async: Could not copy text: ', err)
          }
        )
      }
    },

    preSendInvite() {
      this.contentInviteStatus = `
      <div>Once you send new invite to <b>${this.email.value}</b> an access with current Login and Password will be denied.</div>
      `
      this.isVisibleInviteStatusConfirmAlert = true
    },

    alertConfirmInviteStatus(result) {
      if (result) this.sendInvite()
      this.isVisibleInviteStatusConfirmAlert = false
    },

    async sendInvite() {
      if (!this.email.isValid) {
        this.$notify({
          text: 'Email is incorrect',
          type: 'error',
          duration: 2000,
        })
        return
      }

      let contact = await fetchData(
        urls.clientContactsSendInvite + `/${this.id.value}`,
        'GET'
      )
      if (this.invite_status.value === 'Not invited') {
        this.$notify({
          text: `Invite to the system sent to ${this.email.value}`,
          type: 'success',
          duration: 2000,
        })
      }

      if (contact && typeof contact === 'object') {
        this.invite_status.value = contact.invite_status
        this.invite_status.valueOriginal = contact.invite_status
        this.invite_link.value = contact.invite_link
        this.invite_link.valueOriginal = contact.invite_link

        this.inputValueChanged('invite_status')
        this.inputValueChanged('invite_link')
      }
    },

    inputValueChanged(key) {
      this.$emit('contactValueChanged', {
        id: this.contact.id.value,
        key: key,
        value: this[key].value,
        isValid: this[key].isValid,
      })
    },

    setIsActivatedToEditInputs(value) {
      for (const key in this.$data) {
        let dataItem = this.$data[key]
        if (typeof dataItem === 'object' && 'valueOriginal' in dataItem) {
          dataItem.readonly = value
        }
      }
    },

    initFormValues(values) {
      for (const key in values) {
        if (key in this.$data) {
          let dataItem = this.$data[key]
          if ('valueOriginal' in dataItem) {
            dataItem.value = values[key].value
            dataItem.valueOriginal = values[key].valueOriginal
            dataItem.isValid = values[key].isValid
            dataItem.readonly = !this.isActivatedToEdit
          }
        }
      }
    },

    showContextMenu() {
      this.isShowContextMenu = !this.isShowContextMenu
    },

    alertConfirmDelete(result) {
      if (result) {
        this.$emit('contactValueChanged', {
          id: this.contact.id.value,
          key: 'isMarkedToDelete',
          value: true,
        })
        this.$emit('saveForm', true)
      }
      this.isVisibleConfirmAlert = false
    },

    deleteContact() {
      this.contentDeleteInfo = `
        <div class="mb-10">
          Are you sure you want to delete contact 
          ${this.contact.email.value} ${this.contact.first_name.value} ${this.contact.last_name.value} ?
        </div>`
      this.isVisibleConfirmAlert = true
    },

    changeToPrimaty() {
      this.is_primary.value = true
      this.is_primary.isValid = true
      this.inputValueChanged('is_primary')
      this.$emit('saveForm', true)
    },
  },
}
</script>

<style lang="scss">
.is-marked-to-delete {
  opacity: 0.5;
}
</style>
