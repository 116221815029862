<template>
  <div v-if="options.customFooterComponent.data" class="mr-20">
    Sum of all invoices <b>{{ cost }}</b>
  </div>
</template>

<script>
import numberToCommasString from '@/utils/text-formatters.js'
export default {
  props: {
    options: {
      required: true,
    },
  },

  data() {
    return {
      cost: 0,
    }
  },

  watch: {
    'options.customFooterComponent.data': {
      handler() {
        this.setTotals()
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    setTotals() {
      this.cost = `$${numberToCommasString(
        this.options.customFooterComponent.data?.cost || 0
      )}`
    },
  },
}
</script>
