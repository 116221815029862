<template>
  <div>
    <grid
      v-model:columns="columns"
      v-model:selectedRows="selectedRows"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewEntitys"
      @saveRow="saveEntitys"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
      @setTab="setTab"
      @deleteSelected="deleteSelected"
      @exportSelected="exportSelected"
      :listTabs="listTabsProp"
    />
  </div>
  <alert-yes-no
    v-if="isVisibleAfterSaveAlert"
    title="Error"
    :text="textAfterSaveAlert"
    :alertResult="closeAfterSaveAlert"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import { shallowRef } from 'vue'
import NewRowEntitys from '@/components/NewRows/client/NewRowClientInvoice'
import { fetchData, fetchCSV, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'
import numberToCommasString from '@/utils/text-formatters.js'
import CustomFooterComponent from '@/components/client/CustomFooterInvoices'

export default {
  name: 'ClientInvoices',
  mixins: [listMixin],

  emits: ['needToUpdateData', 'setTab'],
  props: {
    listTabsProp: { default: [] },
    client_id: { default: -1 },
    isCanEditGlobal: { default: false },
    isActivatedToEdit: { default: false },
  },

  data() {
    return {
      options: new GrigOptions({
        addButtonText: 'Add invoice',
        isEditable: this.isEditable,
        isDelete: this.isDelete,
        isAdd: this.isAdd,
        isExportToCSV: hasAccess(permissions.export_client_invoices_to_csv),
        customFooterComponent: {
          data: null,
          component: shallowRef(CustomFooterComponent),
        },
      }),

      columns: [
        new GridColumn({
          key: 'invoice_number',
          text: 'Invoice number',
          width: '150px',
        }),

        new GridColumn({
          key: 'amount',
          text: 'Amount',
          width: '100px',
          formatedValue: ({ value }) => {
            let result = numberToCommasString(value)
            if (result !== 0 && !result) return value

            return `$${result}`
          },
        }),

        new GridColumn({
          key: 'issued_date',
          text: 'Issued Date',
          width: '150px',
        }),

        new GridColumn({
          key: 'invoice_status',
          text: 'Status',
          width: '100px',
        }),

        new GridColumn({
          key: 'date_created',
          text: 'Created Date',
          width: '150px',
        }),

        new GridColumn({
          key: 'date_changed',
          text: 'Last Edited Date',
          width: '150px',
        }),

        new GridColumn({
          key: 'created_by',
          text: 'Created By',
          width: '150px',
        }),

        new GridColumn({
          key: 'modified_by',
          text: 'Last Edited By',
          width: '150px',
        }),
      ],

      newEntitys: {
        invoice_number: null,
        amount: null,
        issued_date: null,
        invoice_status: null,
      },

      newRowComponent: shallowRef(NewRowEntitys),
      initToEdit: -1,
      isVisibleAfterSaveAlert: false,
      textAfterSaveAlert: '',
    }
  },

  watch: {
    isActivatedToEdit() {
      this.options.isEditable = this.isEditable
      this.initToEdit = this.isActivatedToEdit
    },

    isAdd() {
      this.options.isAdd = this.isAdd
    },

    isEditable() {
      this.options.isEditable = this.isEditable
    },

    isDelete() {
      this.options.isDelete = this.isDelete
    },

    rows: {
      handler: function () {
        this.$emit('needToUpdateData', 'statistic')
      },
      deep: true,
    },
  },

  computed: {
    isAdd() {
      return (
        hasAccess(permissions.create_client_invoice) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isEditable() {
      return this.isCanEditGlobal && this.isActivatedToEdit && this.initToEdit
      //return hasAccess(permissions.update_client_invoice) && this.isActivatedToEdit && this.initToEdit
    },

    isDelete() {
      return (
        hasAccess(permissions.delete_client_invoice) &&
        this.isActivatedToEdit &&
        this.initToEdit
      )
    },

    isClientContact() {
      return this.$store.state.currentUser?.client ? true : false
    },
  },

  created() {
    this.initToEdit = this.isActivatedToEdit
    this.hideColumns(['created_by', 'modified_by', 'cost', 'comments'])
    this.fetchData()
  },

  methods: {
    closeAfterSaveAlert() {
      this.isVisibleAfterSaveAlert = false
      this.textAfterSaveAlert = ''
    },

    hideColumns(columnKeys = []) {
      for (const key of columnKeys) {
        let col = this.columns.find((item) => item.key === key)
        if (!col) continue

        col.isHide = this.isClientContact
      }
    },

    setTab(id) {
      this.$emit('setTab', id)
    },

    saveNewEntitys(entitys) {
      this.setNewEntitys(entitys)
      this.addRow()
    },

    saveEntitys(entitys) {
      this.editRow(entitys)
    },

    newEntitysToDefault() {
      for (let key in this.newEntitys) {
        this.newEntitys[key] = null
      }
    },

    setNewEntitys(entitys) {
      for (let key in entitys) {
        this.newEntitys[key] = entitys[key]
      }
    },

    async editRow(entitys) {
      this.$store.commit('setIsLoading', true)
      const id = entitys.id
      delete entitys.id

      let body = this.bodyToSave(entitys)
      body = { ...body, ...{ client_id: this.client_id } }

      let result = await fetchData(
        urls.clientInvoiceUpdate + `/${id}`,
        'POST',
        body
      )
      if (result?.need_to_popup) {
        this.textAfterSaveAlert = `
          ${result?.message} <a href='/client/${result?.client_id}' target='_blank'>${result?.client_name}</a>
          `

        this.isVisibleAfterSaveAlert = true
      } else if (!result?.detail) {
        let entitysRow = this.rows.find((item) => item.id === id)
        for (let key in result) {
          entitysRow[key] = result[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let body = this.bodyToSave(this.newEntitys)
      body = { ...body, ...{ client_id: this.client_id } }

      let result = await fetchData(urls.clientInvoiceCreate, 'POST', body)

      // if (result?.need_to_popup) {
      //   this.textAfterSaveAlert = `
      //     ${result?.message} <a href='/client/${result?.client_id}' target='_blank'>${result?.client_name}</a>
      //     `

      //   this.isVisibleAfterSaveAlert = true
      // } else
      if (!result?.detail) {
        this.rows.unshift(result)
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newEntitysToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(ids) {
      let idsLocal = typeof ids === 'object' ? ids : [ids]
      if (!idsLocal || !idsLocal.length) return

      this.$store.commit('setIsLoading', true)

      const deleteEntityQuery = (ids) => {
        let query = ``
        for (const id of ids) {
          query += `invoices_ids=${id}&`
        }

        return query
      }

      let query = `client_id=${this.client_id}&`

      if (idsLocal?.length && idsLocal[0] === 'all') {
        query += query = `${
          this.queryToFetch(1, 'all') & deleteEntityQuery(idsLocal)
        }`
      } else {
        query += deleteEntityQuery(idsLocal)
      }

      let result = await fetchData(
        urls.clientInvoiceDelete,
        'DELETE',
        null,
        query
      )
      if (!result?.detail) {
        for (const id of idsLocal) {
          const idx = this.rows.findIndex((item) => item.id === id)
          this.rows.splice(idx, 1)
        }
        this.selectedRows = []
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async exportToCSVRows(ids) {
      this.$store.commit('setIsLoading', true)

      const exportQuery = (ids) => {
        let query = `client_id=${this.client_id}&`
        for (const id of ids) {
          query += `invoices_ids=${id}&`
        }

        if (query === `client_id=${this.client_id}&`) {
          for (const row of this.rows) {
            query += `invoices_ids=${row.id}&`
          }
        }

        return query
      }

      let query = ''

      if (ids?.length && ids[0] === 'all') {
        query += query = `${this.queryToFetch(1, 'all')}&${this.queryToSort()}`
      }

      query += exportQuery(ids)

      await fetchCSV(
        urls.clientInvoiceToCsv,
        'GET',
        'client-invoices',
        null,
        query
      )

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      if (this.isLoading && !this.listTabs.length) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}&client_id=${this.client_id}&`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += this.queryToSort()

      let result = await fetchData(urls.clientInvoices, 'GET', null, query)

      this.rows = result?.rows || []
      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)
      this.options.customFooterComponent.data = result?.totals || null

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },
  },
}
</script>
