<template>
  <tr class="add-row">
    <td></td>
    <td>
      <div class="add-row-buttons">
        <div class="main-button orange no-background sm" @click="save">
          Save
        </div>
        <div class="main-button no-background sm" @click="cancel">Cancel</div>
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('invoice_number'))"
      >
        <input-text v-model="invoice_number" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('amount'))">
        <input-number v-model="amount" />
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('issued_date'))">
        <input-date v-model="issued_date" />
      </div>
    </td>
    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('invoice_status'))"
      >
        <input-select v-model="invoice_status" />
      </div>
    </td>

    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('date_created'))"
      >
        {{ rowData['date_created'] }}
      </div>
    </td>

    <td>
      <div
        class="table-cell"
        :style="setWidthColumn(getColumn('date_changed'))"
      >
        {{ rowData['date_changed'] }}
      </div>
    </td>

    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('created_by'))">
        {{ rowData['created_by'] }}
      </div>
    </td>
    <td>
      <div class="table-cell" :style="setWidthColumn(getColumn('modified_by'))">
        {{ rowData['modified_by'] }}
      </div>
    </td>

    <td></td>
    <td></td>
  </tr>
  <alert-yes-no
    v-if="isVisibleConfirmAlert"
    title="Error"
    :text="textConfirmAlert"
    :alertResult="alertConfirmResult"
    :hideYes="false"
    :hideNo="true"
  />
</template>

<script>
import InputSelect from '@/components/inputs/InputSelect'
import InputNumber from '@/components/inputs/InputNumber'
import InputText from '@/components/inputs/InputText'
import InputDate from '@/components/inputs/InputDatePicker'

import { fetchData, urls } from '@/utils/urls.js'
import { newRowMixin } from '@/utils/new-row-mixin'

export default {
  name: 'NewRowClientInvoice',
  mixins: [newRowMixin],
  components: {
    InputSelect,
    InputNumber,
    InputText,
    InputDate,
  },

  data() {
    // let regExpUrl =
    //   /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

    return {
      invoice_number: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      amount: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      issued_date: {
        value: null,
        isValid: false,
        clearable: true,
        required: true,
        readonly: false,
        errorKey: '',
        class: 'sm',
      },

      invoice_status: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: false, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
        class: 'sm',
      },
    }
  },

  async created() {
    await this.fetchDataInvoice_status()

    for (let key in this.rowData) {
      const element = this.rowData[key]
      if (key in this.$data) {
        if (element && typeof element === 'object' && element.length)
          this.$data[key].value = element
        else if (!element) continue
        else if (key === 'invoice_status') this.$data[key].value = [element]
        else this.$data[key].value = element
        if (element || element === 0 || element === false)
          this.$data[key].isValid = true
      }
    }
  },

  computed: {
    isValid() {
      for (const key in this.$data) {
        let entityValue = this.$data[key]
        if (
          typeof entityValue === 'object' &&
          'isValid' in entityValue &&
          !entityValue.isValid
        )
          return false
      }

      return true
    },
  },

  methods: {
    setBoolValue(key, value) {
      this.$data[key].value = value
    },

    async fetchDataInvoice_status() {
      let query = `page=1&per_page=all&invoice_status=asc`
      let result = await fetchData(
        urls.clientInvoiceStatuses,
        'GET',
        null,
        query
      )

      this.invoice_status.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.invoice_status,
          name: item.invoice_status,
        }
      })
    },

    cancel() {
      this.$emit('cancel')
    },

    save() {
      if (!this.isValid) return

      this.$emit('save', this.rowForSave())
    },
  },
}
</script>
